<template>
  <div class="pdf_page_container">
    <my-header isShowLogo="show" :logoSrc="logo" page="video"></my-header>
    <div class="tips">Double click to enter or exit full screen mode</div>
    <div class="nav_title_box">
      <div
        :class="['item_bar']"
        v-for="(item, index) in pdfTitleList"
        :key="index"
        @click="changeTitleEvent(index, item)"
      >
        <p
          v-if="item.alias == 'a300b2d49a63a136'"
          :class="{ active: index === titleIndex }"
        >
          Statement<span>TM</span> and Anthem<span>TM</span> Brochure
        </p>

        <p
          v-if="item.alias != 'a300b2d49a63a136'"
          :class="{ active: index === titleIndex }"
        >
          {{ item.name }}
        </p>
        <figure
          :class="[
            index === titleIndex ? 'block' : '',
            titleIndex === 4 || titleIndex === 9 ? 'block5' : 'block1',
          ]"
        ></figure>
      </div>
    </div>
    <div class="content_wrap">
      <div class="pdf_scroll_wrap">
        <div class="pdf_item_block">
          <div class="placeholder_gif" v-if="pdfList.length === 0">
            <img src="../assets/images/placeholder.gif" alt="" />
            <p>loading...</p>
          </div>
          <div
            v-else
            class="pdf_item"
            v-for="item in pdfList"
            :key="item.id"
            @click="toPdfDetailEvent(item.file)"
          >
            <figure class="cover_img">
              <img :src="item.images" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logo from "../assets/images/pdf/logo.png";
import MyHeader from "../components/MyHeader.vue";
import { getPdfClass, getPdfList } from "../api/home";
import { mapState } from "vuex";
export default {
  components: {
    MyHeader,
  },
  props: {},
  data() {
    return {
      logo,
      portVideo: [],
      pdfList: [],
      pdfTitleList: [],
    };
  },
  created() {
    this.getPdfClass();
    this.getPdfList();
  },
  mounted() {},
  computed: {
    ...mapState({
      titleIndex: (state) => state.pdf.titleIndex,
    }),
  },
  methods: {
    toPdfDetailEvent(pdf) {
      const postUrl = process.env.NODE_ENV === "production" ? "https://www.kohlerapdigitalhub.com" : "https://kohlerap-ref.brandsh.cn";
      const url = `${postUrl}${pdf}`;
      const pdfUrl = `${postUrl}/static/pdfJsOldVersion/web/viewer.html`;
      const srcUrl = `${pdfUrl}?file=${url}`;
      this.$router.push(`/pdf-detail?pdfUrl=${srcUrl}`);
    },
    /** 视频分类 */
    async getPdfClass() {
      const res = await getPdfClass();
      this.pdfTitleList = res.data;
    },

    /** 视频列表 */
    async getPdfList() {
      const res = await getPdfList();
      this.portVideo = res.data;
      const filterVideoList = this.filterClassVideoFunc(
        res.data,
        this.pdfTitleList[this.titleIndex].alias
      );
      this.pdfList = filterVideoList;
    },
    // 筛选分类视频列表
    filterClassVideoFunc(data, alias) {
      return data.filter((item) => {
        return item.category === alias;
      });
    },
    /** title 改变 */
    changeTitleEvent(index, item) {
      this.$store.commit("pdf/setTitleIndex", index);
      this.$store.commit("pdf/setTitleId", item.alias);
      const filterVideoList = this.filterClassVideoFunc(
        this.portVideo,
        item.alias
      );
      this.pdfList = filterVideoList;
    },
  },
  watch: {},
};
</script>
<style lang="scss">
@import "../assets/scss/_fun.scss";
.pdf_page_container {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background-image: url("../assets/images/pdf/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-left: vw(50);
  .tips {
    position: fixed;
    top: vw(80);
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px !important;
    font-family: Helvetica;
    opacity: 0.5;
    z-index: 9;
  }
  .nav_title_box {
    position: relative;
    width: vw(1802);
    height: vw(190);
    margin-top: vw(53);
    background: rgba(0, 0, 0, 0.1);
    border: vw(1) solid #ffffff;
    backdrop-filter: blur(10px);
    display: flex;
    flex-wrap: wrap;
    .item_bar {
      position: relative;
      width: vw(360);
      height: vw(95);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .block {
        position: absolute;
        bottom: vw(1);
        width: 100%;
        height: vw(110);
        background-color: #fff;
        box-shadow: vw(4) vw(1) vw(18) 0px rgba(0, 0, 0, 0.2);
        border-top-left-radius: vw(18);
        border-top-right-radius: vw(18);
        z-index: -1;
      }
      .block5 {
        right: vw(-1);
      }
      .block1 {
        left: vw(-1);
      }
      p {
        position: relative;
        font-size: rem(20);
        line-height: 1;
        font-family: Helvetica;
        color: #ffffff;
        &.active {
          font-weight: bold;
          color: #000000;
        }
      }

      span {
        font-size: vw(8);
      }
    }
    &::after {
      content: "";
      width: 100%;
      height: vw(1);
      top: 50%;
      left: 0;
      background: #ffffff;
      position: absolute;
    }
  }

  .content_wrap {
    position: relative;
    width: vw(1850);
    height: auto;
    margin-top: vw(29);
    .pdf_scroll_wrap {
      position: relative;
      width: 100%;
      height: vw(664);
      margin-right: vw(36);
      overflow: auto;
      .pdf_item_block {
        display: flex;
        width: vw(1802);
        height: 100%;
        flex-wrap: wrap;
        justify-content: center;
        .placeholder_gif {
          width: vw(1800);
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: vw(50);
          img {
            width: vw(60);
          }
          p {
            font-size: rem(30);
            color: #fff;
            opacity: 0.5;
          }
        }
        .pdf_item {
          position: relative;
          width: vw(392);
          height: vw(504);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: vw(34);
          margin-bottom: vw(35);
          margin-right: vw(78);
          z-index: 1;
          cursor: pointer;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          .cover_img {
            width: 100%;
            height: 100%;
          }
        }
        .pdf_item:nth-child(1),
        .pdf_item:nth-child(2),
        .pdf_item:nth-child(3),
        .pdf_item:nth-child(4) {
          margin-top: 0;
        }
      }
    }
    .pdf_scroll_wrap::-webkit-scrollbar {
      width: vw(28) !important;
    }
    .pdf_scroll_wrap::-webkit-scrollbar-thumb {
      background: rgba($color: #ffffff, $alpha: 0.8);
      border-radius: vw(14);
      backdrop-filter: blur(10px);
      width: vw(5) !important;
    }
    .pdf_scroll_wrap::-webkit-scrollbar-button {
      display: none;
    }
    .pdf_scroll_wrap::-webkit-scrollbar-track {
      border-radius: vw(14);
      backdrop-filter: blur(10px);
      background: rgba($color: #000000, $alpha: 0.65);
      width: vw(28) !important;
    }
  }
}
.pdf_page_container {
  // min-width: 1200px 表示当屏幕宽度大于等于800px时，该样式生效。
  // max-width: 1200px 表示当屏幕宽度小于等于600px时，该样式生效。
  @media screen and (max-width: 1200px) {
    .nav_title_box {
      margin-top: 80px;
    }
    .content_wrap {
      margin-top: 40px;
    }
    .placeholder_gif {
      margin-top: 50;
      img {
        width: 50px;
      }
    }
  }
}
</style>
